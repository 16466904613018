/**
* REFACTOR: This file has been lifted + shifted from /components/login and
*           styled for the dark modal theme.
**/

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { compose, withState } from 'recompose'

import styles from './checkbox.css'
import uncheckedIcon from '../../../../../images/neon/checkbox-unchecked.svg'
import checkedIcon from '../../../../../images/neon/checkbox-checked.svg'

function Checkbox({
  isChecked,
  id,
  label,
  labelClassName,
  onChange,
  setChecked,
  checked,
  value,
  className
}) {
  const icon = checked ? checkedIcon : uncheckedIcon
  const onCLick = evt => {
    evt.stopPropagation()
    evt.preventDefault()
    onChange(evt, value, !checked)
    setChecked(prevChecked => !prevChecked)
  }
  const wrapperClass = className || styles.checkboxWrapper

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div data-lbx-e2e="checkbox" onClick={onCLick} className={wrapperClass}>
      <img // eslint-disable-line jsx-a11y/no-static-element-interactions
        onKeyPress={onCLick}
        tabIndex={0}
        src={icon}
        alt={`${isChecked}`}
      />
      {label && (
        <label className={classNames(labelClassName)} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  )
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  setChecked: PropTypes.func,
  labelClassName: PropTypes.string,
  className: PropTypes.string
}

Checkbox.defaultProps = {
  id: '',
  label: '',
  onChange: (evt, value, checked) => {},
  value: '',
  setChecked: checked => {},
  labelClassName: '',
  className: ''
}

export default compose(
  withState('checked', 'setChecked', props => props.isChecked)
)(Checkbox)
