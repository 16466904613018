import React from 'react'
import classNames from 'classnames'

import { useStyles } from './subscription-voucher-modal.styles'
import { useSubscriptionModalVoucher } from '../../hooks/use-subscription-modal-voucher'

const SubscriptionVoucherModal = () => {
  const styles = useStyles()
  const {
    error,
    addVoucherHandler,
    skipVoucherHandler,
    submitting,
    inputHandler,
    voucherCode
  } = useSubscriptionModalVoucher()

  return (
    <div className={styles.voucherContainer}>
      <div className={styles.title}>
        Do you have a voucher code?
        <br />
        Enter it here.
      </div>
      <div className={styles.inputContainer}>
        <label className={styles.label}>
          <input
            value={voucherCode}
            name="voucherCode"
            type="text"
            disabled={submitting}
            onChange={event => inputHandler(event)}
            className={classNames(styles.input, {
              [styles.inputError]: Boolean(error)
            })}
          />
        </label>

        <p
          className={classNames(styles.fieldError, {
            [styles.visible]: Boolean(error)
          })}
        >
          {error}
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <button
          disabled={submitting}
          onClick={addVoucherHandler}
          className={classNames(styles.primaryButton, styles.buttons)}
        >
          Redeem
        </button>
        <button
          onClick={skipVoucherHandler}
          className={classNames(styles.secondaryButton, styles.buttons)}
        >
          Skip
        </button>
      </div>
    </div>
  )
}

export default SubscriptionVoucherModal
