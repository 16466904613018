import { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'
import { useLazyQuery } from '@apollo/react-hooks'
import { path } from 'ramda'
import { useDispatch } from 'react-redux'

import { modalContent } from '../../shared/subscription-constants'
import VOUCHER_WITH_SKU_QUERY from '../../../../graphql/queries/voucher-with-sku.gql'
import { getGQLErrorMsg, getGQLErrorCode, getGQLErrorDetails } from '../../../lib/apollo'
import { MW_ERRORS } from '../../../constants'
import {
  setVoucherValidateError,
  setVoucherValidateType
} from '../../../actions'
import { usePlanChangeFlag } from '../../../hooks/usePlanChange'

export const useSubscriptionModalVoucher = () => {
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [voucher, setVoucher] = useState('')
  const getPlanDetails = usePlanChangeFlag()

  const dispatch = useDispatch()
  dispatch(setVoucherValidateError(''))
  dispatch(setVoucherValidateType(''))

  const subscriptions = [
    getPlanDetails.basic,
    getPlanDetails.standard,
    getPlanDetails.annual
  ]

  const [validateVoucher] = useLazyQuery(VOUCHER_WITH_SKU_QUERY, {
    onCompleted: result => {
      setSubmitting(false)
      const voucherType = path(['voucherWithSku', 'type'], result)
      if (voucherType !== 'SVOD') {
        setError('Only subscription vouchers are accepted')
      } else {
        history.push({
          ...location,
          search: qs.stringify({
            ...qs.parse(location.search),
            content: getNextContent(),
            voucher
          })
        })
      }
    },
    onError: errorResult => {
      setSubmitting(false)

      const errorMsg = getGQLErrorMsg(errorResult)
      const errorCode = getGQLErrorCode(errorResult)
      const errorDetails = getGQLErrorDetails(errorResult)

      setError(errorMsg)

      if (errorCode === MW_ERRORS.VOUCHER_NOT_APPLICABLE) {
        dispatch(setVoucherValidateError(errorMsg))
        dispatch(setVoucherValidateType(getPlanTitleBySku(errorDetails.voucherSku)))

        history.push({
          ...location,
          search: qs.stringify({
            ...qs.parse(location.search),
            content: modalContent.voucherValidateError
          })
        })
      }
    }
  })

  const location = useLocation()
  const history = useHistory()

  const inputHandler = (event) => {
    setVoucher(event.target.value)
  }

  const addVoucherHandler = () => {
    setSubmitting(true)
    validateVoucher({
      variables: {
        voucherCode: voucher,
        signupSku: getSkuByPlanType()
      }
    })
  }

  /**
   * Checking user has credit card status to get next content params
   */
  const getNextContent = () => {
    // TODO checking credit card to change to payment or card picker
    return modalContent.payment
  }

  /**
   * Handle skip button
   */
  const skipVoucherHandler = () => {
    history.push({
      ...location,
      search: qs.stringify({
        ...qs.parse(location.search),
        content: getNextContent()
      })
    })
  }

  const getSkuByPlanType = () => {
    const { plan } = qs.parse(location.search)
    return subscriptions.find(sub => sub.type === plan).sku || ''
  }

  const getPlanTitleBySku = (sku) => {
    return subscriptions.find(sub => sub.sku === sku).title || ''
  }

  return {
    error,
    addVoucherHandler,
    skipVoucherHandler,
    submitting,
    inputHandler,
    voucher
  }
}
