import React from 'react'
import qs from 'query-string'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Button from '../../../forms/neon/button'
import { modalContent } from '../../../../modules/shared/subscription-constants'
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag'
import { MODALS } from '../../../../constants'
import { segmentTrackClickOnWelcomeBackModal } from '../../../../segment/segment-track'
import styles from './index.css'

const Step1New = (props) => {
  const { onRequestClose } = props
  const show2024StandardDiscount = useFeatureFlag('2024_Standard_Discount')
  const offerHeaderLine1 = 'Welcome back! Get 50% off for 2 months,'
  const offerHeaderLine2 = 'only $9.99 per month on a Standard plan'
  const genericHeader = 'Welcome back to Neon!'
  // when we have promotional price(controlled by FF), we use special msg, otherwise general msg
  const dialogMsg = show2024StandardDiscount
    ? 'Click to add your plan now. Limited time offer, ends 30 April 2024.'
    : 'Click to add your plan now.'

  const history = useHistory()
  // Go to price table
  const onClickOk = () => {
    // Add segment data analytics for winback user
    segmentTrackClickOnWelcomeBackModal({
      choosePlan: true,
      backToBrowse: false
    })

    history.replace({
      ...location,
      search: qs.stringify({
        content: modalContent.selection,
        modal: MODALS.qsParams.addSubscription
      })
    })
  }

  const onClickClose = () => {
    // Add segment data analytics for winback user
    segmentTrackClickOnWelcomeBackModal({
      choosePlan: false,
      backToBrowse: true
    })

    onRequestClose()
  }

  return (
    <div className={styles.wrapper}>
      {show2024StandardDiscount ? (
        <h2 className={styles.header}>
          {offerHeaderLine1}
          <br />
          {offerHeaderLine2}
        </h2>
      ) : (
        <h2>{genericHeader}</h2>
      )}
      <p className={styles.copy}>{dialogMsg}</p>
      <div className={styles.buttonRow}>
        <Button
          onClick={onClickOk}
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          text="Choose Plan"
        />
        <Button
          secondary
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          onClick={onClickClose}
          text="Back to browse"
        />
      </div>
    </div>
  )
}

Step1New.propTypes = {
  onRequestClose: PropTypes.func.isRequired
}

export default Step1New
