import React from 'react'
import PropTypes from 'prop-types'

import Form from '../../../forms/layout-form'
import Row from '../../../forms/layout-row'
import Col from '../../../forms/layout-column'
import Input from '../../../forms/neon/input'
import Button from '../../../forms/neon/button'

import voucherStyles from './index.css'

const Step1 = (props) => {
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    browseAsGuest,
    values,
    errors
  } = props
  return (
    // TODO: LBXW-1830
    <div className={voucherStyles.wrapper}>
      <h2 className={voucherStyles.header}>Resume your Neon subscription</h2>
      <p className={voucherStyles.copy}>
        To continue watching, please update your payment details or enter a voucher code
      </p>
      <div className={voucherStyles.formWrapper}>
        <div className={voucherStyles.formLabel}>
          Have a voucher code? Enter it here.
        </div>
        <Form isSubmitting={isSubmitting} handleSubmit={handleSubmit}>
          <Row>
            <Col>
              <Input
                placeholder="(Optional)"
                name="voucherCode"
                value={values.voucherCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                text="Restart my subscription"
                disabled={isSubmitting}
                error={errors.voucherCode}
              />
            </Col>
          </Row>
        </Form>
        <a data-lbx-e2e="browse-without-tv-plan" className={voucherStyles.dismissModal} onClick={browseAsGuest}>
          Browse without a subscription
        </a>
      </div>
    </div>
  )
}

Step1.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  browseAsGuest: PropTypes.func.isRequired,
  values: PropTypes.shape({
    voucherCode: PropTypes.string.isRequired
  }).isRequired,
  errors: PropTypes.shape({
    voucherCode: PropTypes.string
  })
}

Step1.defaultProps = {
  errors: { voucherCode: '' }
}

export default Step1
