import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'
import { useFeatureFlag } from '../../../hooks/useFeatureFlag'
import { modalContent, planType } from '../../shared/subscription-constants'
/**
 * A hook to handle user next step after select plan
 * @returns {{ processSelection: function }}
 */
export const useSubscriptionModalSelection = () => {
  const location = useLocation()
  const history = useHistory()
  const show2024StandardDiscount = useFeatureFlag('2024_Standard_Discount')
  /**
   * Direct to payment if user doesn't has valid card
   * if has valid will subscribe select plan
   * if in show page and select monthly plan, will direct to voucher page
   * @param plan - planType
   */
  const processSelection = (plan) => {
    const isInMyAccount = /^\/my-account\//g.test(location.pathname)
    const selectedStandardInOfferPeriod = plan === planType.STANDARD && show2024StandardDiscount
    const content = isInMyAccount || selectedStandardInOfferPeriod ? modalContent.payment : modalContent.voucher
    const updatedContent = { ...qs.parse(location.search) }
    delete updatedContent.currentSubscription

    history.push({
      ...location,
      search: qs.stringify({
        ...updatedContent,
        plan,
        content
      })
    })
  }

  return {
    processSelection
  }
}
