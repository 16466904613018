import { graphql } from 'react-apollo'
import { path, isEmpty, propEq } from 'ramda'
import SUBSCRIPTION_PAYMENT_VIEW_QUERY from '../../../../../../graphql/queries/subscription-payment-view.gql'
import ACCOUNT_QUERY_WITH_DIR from '../../../../../../graphql/queries/account-with-directives.gql'
import ACCOUNT_CREDIT_CARDS from '../../../../../../graphql/queries/account-credit-cards.gql'
import SUBSCRIBE_MUTATION from '../../../../../../graphql/mutations/subscribe.gql'
import { getPlanDetailById } from '../../../../../modules/shared/helper'
import { segmentTrackInitiatePurchase } from '../../../../../segment/segment-track'

export const withSubscriptionPaymentViewQuery = graphql(
  SUBSCRIPTION_PAYMENT_VIEW_QUERY,
  {
    name: 'paymentViewQuery',
    options: ({ payload: { voucher, subscriptionId } }) => {
      return {
        variables: {
          voucher,
          subscriptionId,
          promotions: true
        },
        fetchPolicy: 'network-only'
      }
    },
    props: ({
      ownProps: {
        payload, renderStep, savedOrderId, onRequestClose
      },
      paymentViewQuery: {
        loading: paymentViewLoading,
        error: paymentViewError,
        subscriptionPaymentView
      }
    }) => ({
      onIframeLoad: iframeQueryParams => {
        if (isEmpty(iframeQueryParams)) return

        if (propEq('status', 'success', iframeQueryParams)) {
          const orderId = savedOrderId || path(['orderId'], iframeQueryParams)
          renderStep(4, { orderId, voucher: path(['voucher'], payload) })
        }

        // Add segment data analytics for initiating payment
        const selectedPlan = getPlanDetailById(payload?.subscriptionId)
        const sku = selectedPlan?.sku
        const paymentDue = subscriptionPaymentView.details[0]?.value
        const payment = Number(subscriptionPaymentView.details[1]?.value?.replace('$', ''))
        segmentTrackInitiatePurchase({
          ...selectedPlan,
          sku,
          paymentDue,
          payment,
          voucher: payload?.voucher
        })
      },
      onCancel: () => {
        onRequestClose()
      },
      onClickContinueCta: (ownProps, paymentViewQuery) => {
        renderStep(4, {
          orderId: savedOrderId,
          voucher: path(['voucher'], payload)
        })
      },
      subscriptionId: payload.subscriptionId,
      isVoucherApplied: !isEmpty(path(['voucher'], payload)),
      paymentViewLoading,
      paymentViewError,
      ...(subscriptionPaymentView || {})
    })
  }
)

// Given that we explicitly get an `orderId` with the paymentViewQuery,
// we need to refetch the account subscription information, so
// that the UI gets updated. This is specifically when a user redeems
// a voucher (Spark) that doesn't require a payment method, but has an effect
// on their previous subscription status.
export const withAccountQueryWithDir = graphql(ACCOUNT_QUERY_WITH_DIR, {
  name: 'accountQuery',
  options: {
    variables: {
      withSubscription: true
    },
    fetchPolicy: 'network-only'
  },
  props: ({ ownProps }) => ({
    ...ownProps
  }),
  skip: ({ orderId }) => orderId == null
})

export const withAccountCreditCardsQuery = graphql(ACCOUNT_CREDIT_CARDS, {
  name: 'accountCreditCardsQuery',
  options: {
    fetchPolicy: 'network-only'
  },
  props: ({
    accountCreditCardsQuery: {
      loading: accountCreditCardsLoading,
      error: accountCreditCardsError,
      account: accountCreditCards
    }
  }) => ({
    accountCreditCardsLoading,
    accountCreditCardsError,
    ...(accountCreditCards || {})
  })
})

export const withSubscriptionMutation = graphql(SUBSCRIBE_MUTATION, {
  props: handleProps
})

function handleProps({
  ownProps: {
    renderStep,
    payload: { voucher, subscriptionId }
  },
  mutate: subscribeToPlan
}) {
  return {
    subscribeToPlan,
    onCompletePayment: paymentMethodId => {
      if (subscribeToPlan) {
        return subscribeAndStepToConfirmation({
          subscribeToPlan,
          subscriptionId,
          voucher,
          paymentMethodId,
          renderStep
        })
      }
      return renderStep(3, {
        voucher,
        subscriptionId,
        addSubscriptionFlow: true
      })
    }
  }
}

export const subscribeAndStepToConfirmation = ({
  subscribeToPlan,
  subscriptionId,
  voucher,
  paymentMethodId,
  renderStep
}) => {
  return subscribeToPlan({
    variables: { productId: subscriptionId, voucher, paymentMethodId }
  })
    .then(response => {
      if (!response.errors) {
        renderConfirmationViewWithOrderID({ response, renderStep, voucher })
      } else {
        renderStep(1)
      }
    })
    .catch(() => {
      renderStep(3, {
        voucher,
        subscriptionId,
        addSubscriptionFlow: true
      })
    })
}

export const renderConfirmationViewWithOrderID = ({
  response,
  renderStep,
  voucher
}) => {
  const {
    data: { subscribe: orderId }
  } = response
  renderStep(4, { orderId, voucher })
}
