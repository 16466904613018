import {
  compose,
  onlyUpdateForPropTypes
} from 'recompose'
import React from 'react'
import PropTypes from 'prop-types'

import { Formik } from 'formik'
import Yup from '../../../../../lib/yup'

import Form from '../../../../forms/layout-form'
import Row from '../../../../forms/layout-row'
import Col from '../../../../forms/layout-column'
import Input from '../../../../forms/neon/input'
import Button from '../../../../forms/neon/button'

import { VOUCHERS } from '../../../../../constants'

import styles from './subscription-voucher-view.css'

function SubscriptionVoucherForm({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  error,
  errors
}) {
  return (
    <Form formClassName={styles.form} handleSubmit={handleSubmit} isSubmitting={false}>
      <Row>
        <Col>
          <Input
            inputClass={styles.input}
            name="voucher"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.voucher}
            warning={error || errors.voucher}
            warningClass={styles.warning}
            exclamationClass={styles.exclamation}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            wrapperClassName={styles.buttonWrapper}
            text="Redeem"
            className={styles.button}
          />
        </Col>
      </Row>
    </Form>
  )
}

SubscriptionVoucherForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.shape({
    voucher: PropTypes.string.isRequired
  }).isRequired,
  error: PropTypes.string,
  errors: PropTypes.shape({
    voucher: PropTypes.string
  })
}

SubscriptionVoucherForm.defaultProps = {
  error: '',
  errors: {}
}

const withFormState = compose(
  Formik({
    mapPropsToValues: () => ({ voucher: '' }),
    validateOnChange: true,
    validationSchema: () => Yup.object().shape({
      voucher: Yup.string().min(VOUCHERS.CHAR_LIMIT).required()
    }),
    handleSubmit: ({ voucher }, { props: { handleSubmitVoucher } }) => {
      handleSubmitVoucher(voucher)
    }
  }),
  onlyUpdateForPropTypes
)

export default withFormState(SubscriptionVoucherForm)
