import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'

import SubscriptionSelectionModalNew
  from './components/subscription-selection-modal/subscription-selection-modal-new'
import SubscriptionConfirmationModal
  from './components/subscription-confirmation-modal/subscription-confirmation-modal'
import SubscriptionConfirmationModalMediumView
  from './components/subscription-selection-modal-medium-view/subscription-selection-modal-medium-view'
import SubscriptionErrorModal
  from './components/subscription-error-modal/subscription-error-modal'
import { modalContent } from '../shared/subscription-constants'
import SubscriptionVoucherModal
  from './components/subscription-voucher-modal/subscription-voucher-modal'
import SubscriptionPaymentMethodModal
  from './components/subscription-payment-method-modal/subscription-payment-method-modal'
import VoucherValidateErrorAfterLoginModal
  from '../../containers/modals/voucher-validate-error-after-login'

/**
 * Render subscription modal content according to actions
 */
const STANDARD_OLD_TITLE = 'Premium'
const STANDARD_TITLE = 'Standard'

// eslint-disable-next-line react/prop-types
const PickContentComponent = ({ errorMessage, errorOnClick }) => {
  const { search } = useLocation()
  const {
    content, currentSubscription, oneWayChangeDirection, hasRecurringVouchers
  } = qs.parse(search)

  const getCurrentSubscriptionName = () => {
    // Update title name if api return old title name
    if (currentSubscription === STANDARD_OLD_TITLE) {
      return STANDARD_TITLE
    }

    return currentSubscription
  }

  switch (content) {
    case modalContent.selection:
      return (
        <>
          <SubscriptionSelectionModalNew currentSubscription={getCurrentSubscriptionName()} oneWayChangeDirection={oneWayChangeDirection} hasRecurringVouchers={hasRecurringVouchers} />
          <SubscriptionConfirmationModalMediumView currentSubscription={getCurrentSubscriptionName()} oneWayChangeDirection={oneWayChangeDirection} />
        </>
      )
    case modalContent.payment:
      return <SubscriptionPaymentMethodModal />
    case modalContent.confirmation:
      return <SubscriptionConfirmationModal />
    case modalContent.voucher:
      return <SubscriptionVoucherModal />
    case modalContent.voucherValidateError:
      return <VoucherValidateErrorAfterLoginModal />
    default:
      return <SubscriptionErrorModal errorMessage={errorMessage} onClick={errorOnClick} />
  }
}

const AddSubscriptionContents = () => {
  const history = useHistory()
  const location = useLocation()
  const clickToRefresh = () => {
    history.push(location.pathname)
  }

  return (
    <PickContentComponent
      errorMessage="Please try again"
      errorOnClick={clickToRefresh}
    />
  )
}

export default AddSubscriptionContents
