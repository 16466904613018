import React from 'react'
import PropTypes from 'prop-types'

import { RENTAL_STATUS } from '../../constants'
import { getTimeInWordsLeftToWatch } from '../../lib/date-time'

import styles from './rental-info.css'

function RentalInfo({
  rentalInfo
}) {
  const timeInWordsLeftToStartWatching = getTimeInWordsLeftToWatch(
    rentalInfo.secondsLeftToStartWatching,
    'left to watch'
  )
  const timeInWordsLeftToWatch = getTimeInWordsLeftToWatch(
    rentalInfo.secondsLeftToWatch,
    'left to watch'
  )

  return (
    <div className={styles.rentalInfoWrapper}>
      <span className={styles.status}>{RENTAL_STATUS.RENTED}</span>
      <span className={styles.timeLeft}>
        {
          rentalInfo.secondsLeftToWatch
            ? timeInWordsLeftToWatch
            : timeInWordsLeftToStartWatching
        }
      </span>
    </div>
  )
}

RentalInfo.propTypes = {
  rentalInfo: PropTypes.shape({
    secondsLeftToStartWatching: PropTypes.number,
    secondsLeftToWatch: PropTypes.number
  }).isRequired
}

export default RentalInfo
